<template>
  <v-main class="prd-login--main">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <p>
            <img height="30" src="../assets/images/paradise_logo.svg" alt="Paradise Logo" />
          </p>
          <h3>Cuando todo encaja... ¡Te sientes libre!</h3>
          <v-card class="elevation-12 mt-8">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Acceso de usuario/a</v-toolbar-title>
              <!-- <v-spacer /> -->
            </v-toolbar>
            <v-card-text>
              <v-form v-model="formIsValid" @submit="login" @keyup.native.enter="login">
                <v-text-field
                  v-model="username"
                  label="Email"
                  name="username"
                  type="text"
                  :rules="[this.$data.$globalRules.required]"
                  autofocus
                >
                  <template slot="prepend">
                    <img src="../assets/icons/user.svg" alt="User" />
                  </template>
                </v-text-field>

                <v-text-field
                  v-model="password"
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  :rules="[this.$data.$globalRules.required]"
                >
                  <template slot="prepend">
                    <img src="../assets/icons/password.svg" alt="User" />
                  </template>
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="login"
                :disabled="loading || !username || !password"
                :loading="loading"
              >
                Entrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
  data() {
    return {
      formIsValid: null,
      username: null,
      password: null,
      loading: false,
    };
  },
  methods: {
    async login() {
      if (!this.username || !this.password) return null;

      try {
        this.loading = true;
        const payload = { username: this.username, password: this.password };

        const { data: loginData } = await AuthService.login(payload);
        await this.$store.dispatch("setUserData", loginData);

        const defaultPathRedirect = this.$router.resolve({ name: "home" }).route.fullPath;
        const path_redirect = this.$store.state.loginRedirect || defaultPathRedirect;
        this.redirectPage(path_redirect);
      } catch (e) {
        if (e instanceof TypeError) {
          this.$notifyError(
            undefined,
            "Error en el proceso de acceso, contacta con el administrador. || " + e
          );
        } else {
          if (e.message) {
            this.$notifyError(
              "Error en la autenticación",
              "Las credenciales utilizadas no son correctas. Si no puedes acceder, contacta con el  administrador del sistema."
            );
          } else {
            this.$notifyError(undefined, e);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    redirectPage(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.prd-login--main {
  background: url("../assets/images/login_bg_top-right.svg"),
    url("../assets/images/login_bg_bottom-left.svg");
  background-position: top 5% right 50%, bottom 5% left 50%;
  background-size: 90% auto, 90% auto;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 550px) {
  .prd-login--main {
    background-position: top 20px right 20px, bottom 20px left 20px;
    background-size: auto 25%, auto 60%;
  }
}

@media only screen and (min-width: 900px) {
  body {
    .prd-login--main {
      background: url("../assets/images/login_bg_top-right.svg"),
        url("../assets/images/login_bg_bottom-left.svg"),
        url("../assets/images/login_bg_top-left.svg"),
        url("../assets/images/login_bg_bottom-right.svg");
      background-size: auto 25%, auto 55%, auto 30%, auto 45%;
      background-position: top 20px right 20px, bottom 20px left 20px, top 20px left 20px,
        bottom 18px right 20px;
      background-repeat: no-repeat;
    }
  }
}
</style>
